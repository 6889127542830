import React from 'react';
import '../styles/TextBlockStyles.scss';

const   TextBlockLims = ({ text }) => {
  return (
    <div className="text-block-lims">
      <p><span>LIMS IT-Lab</span>  – упростит процесс прохождения проверок, аккредитации и оптимизирует работу сотрудников в Вашей Лаборатории</p>
    </div>
  );
};

export default TextBlockLims;

import React from "react";
import FeedbackFormComponent from "../FeedbackFormComponent";
import ButtonComponent from "../ButtonComponent";
import logo from "../../assets/logo.svg";
import RuTubeIcon from "../../assets/mdi_rutube.svg";
import vkIcon from "../../assets/ri_vk-fill.svg";
import tgIcon from "../../assets/telgram.svg";
import illustration from "../../assets/illustration.svg";
import WhatsAppIcon from "../../assets/mdi_whatsapp.svg";

const DesktopHeader = ({
  openModal,
  scrollToSection,
  isModalOpen,
  closeModal,
  headerRef,
}) => {
  const scrollTo = (section) => scrollToSection(section);

  const scrollToAbout = (section) => {
    console.log(section);

    document.getElementById(section)?.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div className="header-overlay">
      <header ref={headerRef}>
        <div className="header-container">
          <img
            className="header-logo"
            src={logo}
            alt="Logo"
            onClick={() => scrollTo("about")}
          />
          <div className="header-center">
            <div className="header-top">
              <div className="header-block-1">
                <span className="header-text-2">
                  <a href="tel:+74954450058">+7 (495) 445-00-58</a>

                  <a href="https://wa.me/74954450058">
                    {" "}
                    <img
                      className="header-icon"
                      src={WhatsAppIcon}
                      alt="SVG"
                      onClick={() => scrollToSection("about")}
                    />
                  </a>
                </span>
              </div>
              <div className="header-block-2">
                <div
                  className="header-container-svg"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <a
                    href="https://rutube.ru/channel/8151934/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      className="header-icon-rutube"
                      src={RuTubeIcon}
                      alt="RuTube"
                      style={{ marginTop: "4px" }}
                    />
                  </a>
                </div>
                <div className="header-container-svg">
                  <a
                    href="https://vk.com/adinex2008"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={vkIcon} alt="VKontakte" />
                  </a>
                </div>
                <div className="header-container-svg">
                  <a
                    href="https://t.me/LIMS_lT_lab"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={tgIcon} alt="Telegram" />
                  </a>
                </div>
              </div>
            </div>
            <div className="header-bottom">
              <ul className="header-list">
                <li onClick={()=>scrollToAbout("about")}>О программе</li>
                <li onClick={()=>scrollToAbout("pricing")}>
                  Цены
                </li>
                <li onClick={()=>scrollToAbout("features")}>Возможности</li>
                {/* <li onClick={() => scrollTo('offer')}>Спец. предложение</li> */}
                <li onClick={()=>scrollToAbout("cases")}>Кейсы</li>
                <li onClick={()=>scrollToAbout("details")}>Детально о Lims</li>
                <li onClick={()=>scrollToAbout("news")}>Новости</li>
              </ul>
            </div>
          </div>
          <div className="header-right">
            <ButtonComponent
              className="button-style-1 color-button header-button"
              onClick={openModal}
            >
              <span className="button-text">Заказать консультацию</span>
            </ButtonComponent>
            <img
              src={illustration}
              className="header-svg-3"
              alt="Illustration"
            />
          </div>
        </div>
      </header>
      {isModalOpen && <FeedbackFormComponent onClose={closeModal} />}
    </div>
  );
};

export default DesktopHeader;

import objectIspitanyiImg from "../assets/cardImages/object_ispitanyi.jpg";
import smkImg from "../assets/cardImages/smk.jpg";
import vlkImg from "../assets/cardImages/vlk.jpg";
import graduir from "../assets/cardImages/graduir.jpg";
import protokolIsp from "../assets/cardImages/protokolIsp.jpg";
import reactiv from "../assets/cardImages/reactiv.jpg";
import baseBlock from "../assets/cardImages/baseBlock.jpg";
import minilis from "../assets/cardImages/minilis.jpg";
import price18 from "../assets/cardImages/price-18.png";
import price19 from "../assets/cardImages/price-19.png";
import price21 from "../assets/cardImages/price-21.jpeg";
import price22 from "../assets/cardImages/price-22.jpeg";
import price24 from "../assets/cardImages/price-24.jpeg";
import price25 from "../assets/cardImages/price-25.jpeg";

export const priceData = [
  [
    {
      title: "LIMS IT-Lab. ",
      cardName:`«Базовый блок» `,
      description:
        "В данный блок автоматизации включены модули (оборудование, планировщик, персонал, документы, электронные журналы, паспорт лаборатории, объекты испытаний).",
      price: "315 000,00 руб.",
      buttonText: "Заказать",
      img: baseBlock,
    },
  ],

  [
    {
      title: "LIMS IT-Lab.",
      cardName: " «Пакетная выгрузка во ФГИС» ",
      description: "Настройка выгрузки электронных протоколов во ФГИС.",
      price: "15 000,00 руб.",
      buttonText: "Заказать",
      img: price19,
    },
  ],
  [
    {
      title: "LIMS IT-Lab. ",
      cardName:` «Электронные журналы по токсичности»`,
      description: "Входит комплект электронных журналов по токсичности.",
      price: "18 000,00 руб.",
      buttonText: "Заказать",
      img: price18,
    },
  ],
  [
    {
      title: "LIMS IT-Lab.",
      cardName:`«Архив» `,
      description:
        "Данный модуль позволит работать с архивом документов, утилизировать и хранить в различных облачных источниках.",
      price: "42 000,00 руб.",
      buttonText: "Заказать",
      img: price21,
    },
  ],

  [
    {
      title: "LIMS IT-Lab.",
      cardName:"«Неопределенность» ",
      description:
        "Данный модуль позволит проводить расчет неопределенности в системе. Данный блок входит в модуль ВЛК, но также его можно приобрести отдельно без блока ВЛК.",
      price: "55 000,00 руб.",
      buttonText: "Заказать",
      img: price22,
    },
  ],
  [
    {
      title: "LIMS IT-Lab. ",
      cardName:"«Minilis» ",
      cardNameDesc:"Защита от подделок",
      description:
        "miniLIS - онлайн портал ведения и контроля протоколов испытаний и технической оснащённости. Стоимость указана за месяц.",
      price: "2 400,00 руб.",
      buttonText: "Заказать",
      img: minilis,
    },
  ], 
  
  [
    {
      title: "LIMS IT-Lab ",
      cardName:"«Акт верификации» ",
      cardNameDesc:"Дополнение к базовой системе",
      description: "Услуга включает в себя проведение акта верификации, подтверждающего корректность работы системы в соответствии с установленными требованиями",
      price: "13 000,00 руб.",
      buttonText: "Заказать",
      img: price24,
    },
  ],

  [
    {
      title: "LIMS IT-Lab",
      cardName:"«Протоколы испытании» ",
      description:
"  Используя систему LIMS IT-Lab, вы сможете увеличить эффективность работы лаборатории в целом, соблюдая контроль качества на всех этапах производства.",
      price: "125 000,00 руб.",
      buttonText: "Заказать",
      img: protokolIsp,
    },
  ],
  [
    {
      title: "LIMS IT-Lab",
      cardName:"«СМК»",
      cardNameDesc:"Дополнение к базовой системе",
      description:
        'В системе "LIMS IT-Lab" реализован следующий функционал для автоматизации процессов СМК в Испытательной Лаборатории: Внутренние аудиты; Риски и возможности; Несоответствия и КД; Анкетирование соискателей и сотрудников; Обратная связь с сотрудниками, Анализ со стороны руководства.',
      price: "45 000,00 руб.",
      buttonText: "Заказать",
      img: smkImg,
    },
  ],
  [
    {
      title: "LIMS IT-Lab. ",
      cardName:"«ВЛК»",
      cardNameDesc:"Дополнение к базовой системе",
      description:" В LIMS IT-Lab разработан блок Внутрилабораторного контроля качества (ВЛК), который автоматизирует процесс проверки результатов как на этапе проведения испытаний (внутри электронных журналах), так и отдельным расчетом.",
      price: "190 500,00 руб.",
      buttonText: "Заказать",
      img: vlkImg,
    },
  ],
  [
    {
      title:
        "LIMS IT-Lab",
        cardName:"«Градуировочныe зависимости»",
        cardNameDesc:"Дополнение к базовой системе",
      description:
" LIMS IT-Lab позволяет автоматизировать процесс построения градуировочных зависимостей, провести отбраковку и проверку получаемых значений, а также построить график и провести контроль стабильности.",      price: "13 000,00 руб.",
      buttonText: "Заказать",
      img: graduir,
    },
  ],
  [
    {
      title:
        "LIMS IT-Lab",
        cardName:"«Реактивы» ",
        cardNameDesc:"Дополнение к базовой системе",
      description:
        "В системе LIMS IT-LAB организован учет реактивов по подсистемам: Учет растворов; Учет прекурсоров; Учет реагентов; Учет расходных материалов.",
      price: "20 000,00 руб.",
      buttonText: "Заказать",
      img: reactiv,
    },
  ],
  [
    {
      title: "LIMS IT-Lab",
      cardName:"Модуль «Подписание документов»  ",
      description:
        "Данный модуль является одной из версий программного продукта LIMS IT-Lab, и может поставляться как отдельным блоком, так и в полноценной версии LIMS IT-Lab. Дает возможность подписать электронные документы ЭЦП.",
      price: "125 000,00 руб.",
      buttonText: "Заказать",
      img: price25,
    },
  ],
  [
    {
      title: "LIMS IT-Lab",
      cardName:"«Метрология»",
      cardNameDesc:"Дополнение к базовой системе",
      description: "Дополнение включает в себя инструменты и функции для обеспечения метрологического учета, калибровки и верификации измерительного оборудования.",
      price: "47 000,00 руб.",
      buttonText: "Заказать",
      img: price25,
    },
  ],
];


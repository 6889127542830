import React, { useEffect, useState } from "react";
import SectionTitle from "./SectionTitle";
import ButtonComponent from "./ButtonComponent";
import CertificateModalComponent from "./CertificateModalComponent";
import TextBlockIntroductionFirst from "./TextBlockIntroductionFirst";
import TextBlockIntroductionSecond from "./TextBlockIntroductionSecond";
import mobilePlanSchemeAtom1 from "../assets/mobile-plan-scheme-atom-1.png";
import mobilePlanSchemeAtom2 from "../assets/mobile-plan-scheme-atom-2.png";
import tabletPlanSchemeAtom from "../assets/tablet-plan-scheme-atom.svg";
import planSchemeAtom from "../assets/plan-scheme-atom.svg";
import "../styles/IntroductionSectionStyles.scss";

const IntroductionSection = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [modalVisible, setModalVisible] = useState(false);

  const handleOpenModal = () => {
    setModalVisible(true);
  };

  const handleCloseModal = () => {
    setModalVisible(false);
  };

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (modalVisible) {
      const scrollbarWidth =
        window.innerWidth - document.documentElement.clientWidth;
      document.body.style.overflow = "hidden";
      document.body.style.paddingRight = `${scrollbarWidth}px`;
    } else {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0";
    }

    return () => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0";
    };
  }, [modalVisible]);

  const isDesktop = windowWidth >= 830;
  const isTablet = windowWidth >= 431 && windowWidth < 830;
  const isMobile = windowWidth < 431;

  if (isDesktop) {
    return (
      <div className="introduction-section">
        <div className="introduction-section-container">
          <img
            src={planSchemeAtom}
            alt="plan-scheme-atom"
            className="plan-scheme-atom"
          />
          <SectionTitle title="Внедрение:" />
          <div className="introduction-text-blocks-container">
            <TextBlockIntroductionFirst
              title="LIMS IT-LAB – Инновационная система для автоматизации лабораторной деятельности."
              text={
                <>
                  <p>
                    LIMS IT-LAB — это мощное программное решение,
                    предназначенное для автоматизации лабораторной деятельности.
                    Включенная в Единый реестр российских программ для
                    электронных вычислительных машин и баз данных, наша система
                    охватывает широкий спектр областей и предлагает обширный
                    функционал для повышения эффективности работы лабораторий.
                  </p>
                </>
              }
              listTitle="Преимущества LIMS IT-LAB:"
              listText={`Электронная подпись: Удобная возможность подписывать журналы и протоколы с использованием электронной подписи.
                Планирование работ: Эффективное управление рабочими процессами сотрудников через планирование их задач и календарей
                Штрих-кодирование: Продвинутая функция штрих-кодирования образцов для упрощения их учета и отслеживания.
                Настройка журналов: Интуитивная возможность настраивать журналы любого типа прямо в режиме пользователя.
                Пакетная выгрузка: Легкость формирования пакетной выгрузки данных во ФГИС для упрощения отчетности.
                Разнообразие отчетов: Гибкая система формирования различных отчетов для анализа и мониторинга работы лаборатории.`}
              afterListText="С LIMS IT-LAB вы получаете возможность реализовать любые бизнес-процессы, упрощая и оптимизируя работу вашей лаборатории. Выберите LIMS IT-LAB — и повысьте эффективность и производительность вашего лабораторного контроля!"
            />
            <TextBlockIntroductionSecond
              title="Варианты внедрения системы LIMS IT-LAB"
              text={
                <div className="text-block-introduction-second__text">
                  <p>
                    <strong>Полное внедрение: </strong>
                    Включает настройку всех модулей и обучение сотрудников для
                    максимальной автоматизации лаборатории.
                  </p>
                  <p>
                    <strong>Постепенное внедрение: </strong>
                    Поэтапное подключение критически важных модулей, что
                    минимизирует возможные перебои в работе.
                  </p>
                  <p>
                    <strong>Настройка под специфические требования: </strong>
                    Возможность адаптации системы под уникальные процессы и
                    потребности вашей лаборатории.
                  </p>

                  <p>
                    <strong>Поддержка и обновление: </strong> Регулярные
                    обновления системы и техническая поддержка для обеспечения
                    актуальности и безопасности.
                  </p>

                  <p>
                    <strong>Офлайн тренинги: </strong> Практические занятия на
                    базе вашей лаборатории для эффективного освоения системы.
                  </p>

                  <p>
                    <strong>Индивидуальные консультации: </strong> Персональные
                    сессии с экспертами для решения конкретных задач и вопросов.
                  </p>

                  <p>
                    <strong>
                      Пользовательская документация и видеоинструкции:{" "}
                    </strong>
                    Доступ к обширной базе знаний, включая справочные материалы
                    и обучающие видео.
                  </p>
                  <p>
                    Выберите оптимальный вариант внедрения LIMS IT-LAB и
                    позвольте нам помочь вам оптимизировать лабораторные
                    процессы, повысить качество работы и эффективность вашей
                    организации!
                  </p>
                </div>
              }
            />
          </div>
        </div>
      </div>
    );
  } else if (isTablet) {
    return (
      <div className="tablet-introduction-section">
        <div className="tablet-introduction-section-container">
          <img
            src={tabletPlanSchemeAtom}
            alt="tablet-plan-scheme-atom"
            className="tablet-plan-scheme-atom"
          />
          <SectionTitle title="Внедрение:" />
          <div className="tablet-introduction-text-blocks-container">
            <div className="tablet-text-block">
              <h2>
                LIMS IT-LAB – Инновационная система для автоматизации
                лабораторной деятельности..
              </h2>
              <p>
                LIMS IT-LAB — это мощное программное решение, предназначенное
                для автоматизации лабораторной деятельности. Включенная в Единый
                реестр российских программ для электронных вычислительных машин
                и баз данных, наша система охватывает широкий спектр областей и
                предлагает обширный функционал для повышения эффективности
                работы лабораторий.
              </p>
              <p>Преимущества LIMS IT-LAB::</p>
              <ul>
                <li>
                  Электронная подпись: Удобная возможность подписывать журналы и
                  протоколы с использованием электронной подписи.
                </li>
                <li>
                  Планирование работ: Эффективное управление рабочими процессами
                  сотрудников через планирование их задач и календарей
                </li>
                <li>
                  Штрих-кодирование: Продвинутая функция штрих-кодирования
                  образцов для упрощения их учета и отслеживания.
                </li>
                <li>
                  Настройка журналов: Интуитивная возможность настраивать
                  журналы любого типа прямо в режиме пользователя.
                </li>
                <li>
                  Пакетная выгрузка: Легкость формирования пакетной выгрузки
                  данных во ФГИС для упрощения отчетности.
                </li>
                <li>
                  Разнообразие отчетов: Гибкая система формирования различных
                  отчетов для анализа и мониторинга работы лаборатории.
                </li>
              </ul>
              <h2 className="tablet-introduction-second-text-block-title">
                С LIMS IT-LAB вы получаете возможность реализовать любые
                бизнес-процессы, упрощая и оптимизируя работу вашей лаборатории.
                Выберите LIMS IT-LAB — и повысьте эффективность и
                производительность вашего лабораторного контроля!
              </h2>
              <p>Варианты внедрения системы LIMS IT-LAB</p>
              <ul className="tablet-introduction-second-text-block-list">
                <li>
                  <strong>Полное внедрение: </strong>
                  Этот вариант включает внедрение всех модулей системы LIMS
                  IT-LAB одновременно. Мы предлагаем комплексную настройку и
                  обучение ваших сотрудников, чтобы обеспечить максимальную
                  эффективность и быстрое освоение системы. Это решение идеально
                  подходит для крупных лабораторий, стремящихся к полной
                  автоматизации всех процессов.
                </li>
                <li>
                  <strong>Постепенное внедрение: </strong>
                  Если вы хотите сохранить текущие рабочие процессы, вы можете
                  выбрать поэтапное внедрение. Мы начнем с наиболее критичных
                  модулей, после чего постепенно добавим остальные. Это позволит
                  вашему коллективу адаптироваться к изменениям и минимизирует
                  возможные перебои в работе.
                </li>
                <li>
                  <strong>Настройка под специфические требования: </strong>
                  Наши специалисты готовы адаптировать систему под ваши
                  специфические процессы и требования. Вы можете выбрать только
                  те модули и функции, которые наиболее актуальны для вашей
                  лаборатории, что позволит избежать излишней сложности.
                </li>
                <li>
                  <strong>Поддержка и обновление: </strong>
                  После внедрения мы обеспечиваем постоянную поддержку, а также
                  регулярные обновления системы, чтобы гарантировать ее
                  соответствие современным требованиям и стандартам. Вы всегда
                  будете уверены в актуальности инструментов, используемых в
                  вашей лаборатории.
                </li>

                <li>
                  <strong> Индивидуальные консультации: </strong>
                  Вы можете организовать индивидуальные консультации с нашими
                  специалистами для решения конкретных вопросов и задач,
                  возникающих в процессе работы с системой.
                </li>

                <li>
                  <strong>
                    Пользовательская документация и видеоинструкции:{" "}
                  </strong>
                  Все пользователи получают доступ к обширной базе знаний,
                  которая включает подробные руководства, справочные материалы и
                  обучающие видео, что способствует быстрому освоению системы.
                </li>
              </ul>
              <p>
              Выберите оптимальный вариант внедрения LIMS IT-LAB и позвольте нам помочь вам оптимизировать лабораторные процессы, повысить качество работы и эффективность вашей организации!
              </p>
            </div>
          </div>
        </div>
        {modalVisible && (
          <CertificateModalComponent
            onClose={handleCloseModal}
          ></CertificateModalComponent>
        )}
      </div>
    );
  } else if (isMobile) {
    return (
      <div className="mobile-introduction-section">
        <div className="mobile-introduction-section-container">
          <img
            src={mobilePlanSchemeAtom1}
            alt="mobile-plan-scheme-atom"
            className="mobile-plan-scheme-atom-1"
          />
          <img
            src={mobilePlanSchemeAtom2}
            alt="mobile-plan-scheme-atom"
            className="mobile-plan-scheme-atom-2"
          />
          <SectionTitle title="Внедрение:" />
          <div className="mobile-introduction-text-blocks-container">
            <div className="mobile-text-block">
              <h2>
                LIMS IT-LAB – Инновационная система для автоматизации
                лабораторной деятельности..
              </h2>
              <p>
                LIMS IT-LAB — это мощное программное решение, предназначенное
                для автоматизации лабораторной деятельности. Включенная в Единый
                реестр российских программ для электронных вычислительных машин
                и баз данных, наша система охватывает широкий спектр областей и
                предлагает обширный функционал для повышения эффективности
                работы лабораторий.
              </p>
              <p>Преимущества LIMS IT-LAB::</p>
              <ul>
                <li>
                  Электронная подпись: Удобная возможность подписывать журналы и
                  протоколы с использованием электронной подписи.
                </li>
                <li>
                  Планирование работ: Эффективное управление рабочими процессами
                  сотрудников через планирование их задач и календарей
                </li>
                <li>
                  Штрих-кодирование: Продвинутая функция штрих-кодирования
                  образцов для упрощения их учета и отслеживания.
                </li>
                <li>
                  Настройка журналов: Интуитивная возможность настраивать
                  журналы любого типа прямо в режиме пользователя.
                </li>
                <li>
                  Пакетная выгрузка: Легкость формирования пакетной выгрузки
                  данных во ФГИС для упрощения отчетности.
                </li>
                <li>
                  Разнообразие отчетов: Гибкая система формирования различных
                  отчетов для анализа и мониторинга работы лаборатории.
                </li>
              </ul>
              <h2>
                С LIMS IT-LAB вы получаете возможность реализовать любые
                бизнес-процессы, упрощая и оптимизируя работу вашей лаборатории.
                Выберите LIMS IT-LAB — и повысьте эффективность и
                производительность вашего лабораторного контроля!
              </h2>
              <p>Варианты внедрения системы LIMS IT-LAB</p>
              <ul className="mobile-introduction-second-text-block-list">
                <li>
                  <strong>Полное внедрение: </strong>
                  Этот вариант включает внедрение всех модулей системы LIMS
                  IT-LAB одновременно. Мы предлагаем комплексную настройку и
                  обучение ваших сотрудников, чтобы обеспечить максимальную
                  эффективность и быстрое освоение системы. Это решение идеально
                  подходит для крупных лабораторий, стремящихся к полной
                  автоматизации всех процессов.
                </li>
                <li>
                  <strong>Постепенное внедрение: </strong>
                  Если вы хотите сохранить текущие рабочие процессы, вы можете
                  выбрать поэтапное внедрение. Мы начнем с наиболее критичных
                  модулей, после чего постепенно добавим остальные. Это позволит
                  вашему коллективу адаптироваться к изменениям и минимизирует
                  возможные перебои в работе.
                </li>
                <li>
                  <strong>Настройка под специфические требования: </strong>
                  Наши специалисты готовы адаптировать систему под ваши
                  специфические процессы и требования. Вы можете выбрать только
                  те модули и функции, которые наиболее актуальны для вашей
                  лаборатории, что позволит избежать излишней сложности.
                </li>
                <li>
                  <strong>Поддержка и обновление: </strong>
                  После внедрения мы обеспечиваем постоянную поддержку, а также
                  регулярные обновления системы, чтобы гарантировать ее
                  соответствие современным требованиям и стандартам. Вы всегда
                  будете уверены в актуальности инструментов, используемых в
                  вашей лаборатории.
                </li>

                <li>
                  <strong> Индивидуальные консультации: </strong>
                  Вы можете организовать индивидуальные консультации с нашими
                  специалистами для решения конкретных вопросов и задач,
                  возникающих в процессе работы с системой.
                </li>

                <li>
                  <strong>
                    Пользовательская документация и видеоинструкции:{" "}
                  </strong>
                  Все пользователи получают доступ к обширной базе знаний,
                  которая включает подробные руководства, справочные материалы и
                  обучающие видео, что способствует быстрому освоению системы.
                </li>
              </ul>
              <p>
              Выберите оптимальный вариант внедрения LIMS IT-LAB и позвольте нам помочь вам оптимизировать лабораторные процессы, повысить качество работы и эффективность вашей организации!
              </p>
            </div>
          </div>

        </div>

      </div>
    );
  }
};

export default IntroductionSection;

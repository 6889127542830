import React, { useEffect, useState } from "react";
import "../styles/UrgentPriceStyles.scss";
import banner from "../assets/banner.jpg";
import mobileBanner from "../assets/mobile_banner.jpg";
import FeedbackFormComponent from "./FeedbackFormComponent";
const Banner = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    console.log(windowWidth);

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);


  return (
    <>
      <div className="banner">
        <img
          className="banner=img"
          onClick={()=>openModal()}
          src={windowWidth < 468 ? mobileBanner : banner}
          alt=""
        />
      </div>
      {isModalOpen && <FeedbackFormComponent onClose={closeModal}/>}
    </>
  );
};

export default Banner;

import React, { useEffect, useState } from "react";
import "../styles/PlanSchemeSectionStyles.scss";
import Slider from "react-slick";
import { certificates } from "../assets/certificates";

const Sertificates= () => {

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [currentSlide, setCurrentSlide] = useState(0);
    const [count ,setCount] = useState(3)
  
    useEffect(() => {
      const handleWindowResize = () => {
        setWindowWidth(window.innerWidth);
      };
  
      window.addEventListener("resize", handleWindowResize);
     
      if (windowWidth < 900) {
        setCount(2)
      }
      if (windowWidth < 450) {
        setCount(1)
      }
      
      return () => {
        window.removeEventListener("resize", handleWindowResize);
      };
    }, [windowWidth])


  const CustomPrevArrow = (props) => (
    <div {...props} className="custom-prev-arrow" >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="21"
        height="34"
        viewBox="0 0 21 34"
        fill="none"
      >
        <path
          d="M3.33337 3.66666L16.6667 17L3.33337 30.3333"
          stroke="black"
          stroke-width="6"
          stroke-linecap="round"
        />
      </svg>
    </div>
  );

  const CustomNextArrow = (props) => (
    <div {...props} className="custom-next-arrow" >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="21"
        height="34"
        viewBox="0 0 21 34"
        fill="none"
      >
        <path
          d="M17.6667 3.66666L4.33333 17L17.6667 30.3333"
          stroke="black"
          stroke-width="6"
          stroke-linecap="round"
        />
      </svg>
    </div>
  );

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: count,
    slidesToScroll: 1,
    vertical: false,
    swipeToSlide: true,
    nextArrow: <CustomPrevArrow />,
    prevArrow: <CustomNextArrow />,
    afterChange: (index) => setCurrentSlide(index),
  };

  return (
    <div className="certificates-block">
      <div className="certificates-block-container">
        <h2>Cертификаты:</h2>
        <div className="certificates-block-slider">
          <Slider {...settings}>
            {certificates.map((certificate, index) => (
              <div key={index} className="certificates-block-slider-item" >
                <img src={certificate} alt={`Certificate ${index + 1}`}   />
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default Sertificates;

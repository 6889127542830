 import company1 from "../assets/company-1.png";
 import company2 from "../assets/company-2.png";
 import company3 from "../assets/company-3.png";
 import company4 from "../assets/company-4.png";
 import company5 from "../assets/company-5.png";
 import company6 from "../assets/company-6.png";
 import company7 from "../assets/company-7.png";
 import company8 from "../assets/company-8.png";
 import company9 from "../assets/company-9.png";
 import company10 from "../assets/company-10.png";
 import company11 from "../assets/company-11.svg";
 
 export const companyLogos = [
    { src: company1, name: "АО НИЦ АЭС" },
    { src: company2, name: "АНО СЦКС" },
    { src: company3, name: "НИИЖБ" },
    { src: company4, name: "Берзовский рудник" },
    { src: company5, name: "Битумикс" },
    { src: company6, name: "Почвенный Институт им. В.В. Докучаева" },
    { src: company7, name: "Мосавтадор" },
    { src: company8, name: "Текнофид" },
    { src: company9, name: "РГАН-МСХА" },
    { src: company10, name: "ЦЛАТИ" },
    { src: company11, name: "РолсИзомаркет" },
  ];
import React, { useEffect, useState } from "react";
import PriceCard from "./PriceCard";
import SectionTitle from "./SectionTitle";
import mobilePriceAtom1 from "../assets/mobile-price-atom-1.svg";
import "../styles/PriceInfoSectionSliderStyles.scss";
import Slider from "react-slick";
import {
  priceData,
  priceDataMobile,
  priceDataTablet,
} from "../assets/priceCards";

const PriceInfoSectionSlider = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [priceCards, setPriceCards] = useState(priceData);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  
  const [count ,setCount] = useState(3)
  
    useEffect(() => {
      const handleWindowResize = () => {
        setWindowWidth(window.innerWidth);
      };
  
      window.addEventListener("resize", handleWindowResize);
      if (windowWidth < 800) {
        setCount(2)
      }
      
      if (windowWidth < 1025) {
        setCount(2)
      }

      if (windowWidth < 450) {
        setCount(1)
      }
      
      return () => {
        window.removeEventListener("resize", handleWindowResize);
      };
    }, [windowWidth])


  const CustomPrevArrow = (props) => (
    <div {...props} className="custom-prev-arrow" style={{top:'40%'}}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="21"
        height="34"
        viewBox="0 0 21 34"
        fill="none"
      >
        <path
          d="M3.33337 3.66666L16.6667 17L3.33337 30.3333"
          stroke="black"
          stroke-width="6"
          stroke-linecap="round"
        />
      </svg>
    </div>
  );

  const CustomNextArrow = (props) => (
    <div {...props} className="custom-next-arrow" style={{top:'40%'}}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="21"
        height="34"
        viewBox="0 0 21 34"
        fill="none"
      >
        <path
          d="M17.6667 3.66666L4.33333 17L17.6667 30.3333"
          stroke="black"
          stroke-width="6"
          stroke-linecap="round"
        />
      </svg>
    </div>
  );

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: count,
    slidesToScroll: 1,
    vertical: false,
    swipeToSlide: true,
    nextArrow: <CustomPrevArrow />,
    prevArrow: <CustomNextArrow />,
    afterChange: (index) => setCurrentSlide(index),
  };

  return (
    <div id="pricing" className="price-info">
      <div className="price-info-section-container">
        <img
          src={mobilePriceAtom1}
          alt="price-atom-1"
          className="mobile-price-atom-1"
        />
        <SectionTitle title="Цены:" />
        <div className="price-cards-container">
          <Slider {...settings}>
            {priceCards.map((group, index) => (
              <div key={index} className="slider-container">
                {group.map((card, cardIndex) => (
                  <PriceCard
                    key={cardIndex}
                    className="style-1"
                    title={card.title}
                    cardName={card.cardName}
                    cardNameDesc={card?.cardNameDesc}
                    price={card.price}
                    description={card.description}
                    img={card.img}
                    buttonText={card.buttonText}
                    isDop={card.isDop}
                  />
                ))}
              </div>
            ))}
          </Slider>
        </div>
        {
          windowWidth > 450 &&(
            <div className="dots-container">
            {priceCards.map((group, groupIndex) =>
              group.map((_, cardIndex) => (
                <div
                  key={`${groupIndex}-${cardIndex}`} 
                  className={`dot ${currentSlide === groupIndex ? "active" : ""}`} 
                ></div>
              ))
            )}
          </div>
          )
        }
  
      </div>
    </div>
  );
};

export default PriceInfoSectionSlider;

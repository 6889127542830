import React from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import "../styles/ModalComponentStyles.scss";

const DocumentModalComponent = ({ onClose, content }) => {
  return ReactDOM.createPortal(
    <>
      <div className="document-modal-overlay" onClick={onClose}></div>
      <div className="document-modal">
        <div className="document-modal-header">
          <button
            className={`document-modal-close ${
              content.type === "document" && "document-close"
            }`}
            onClick={onClose}
          >
            ✖
          </button>
        </div>
        {content.type === "document" ? (
          <img
            src={content.content}
            width="100%"
            height="900px"
            alt="Документ"
          />
        ) : (
        <>
          <div className="document-modal-text-content">
            <div
              className="scrollable-content"
              dangerouslySetInnerHTML={{ __html: content.content,}}
            />
            <div className="document-modal-footer">
              <button className=" document-modal-footer-close-btn button-style-4" onClick={onClose}>
                Закрыть
              </button>
            </div>
          </div>
        </>
        )}
      </div>
    </>,
    document.body
  );
};

DocumentModalComponent.propTypes = {
  onClose: PropTypes.func.isRequired,
  content: PropTypes.shape({
    type: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
  }).isRequired,
};

export default DocumentModalComponent;

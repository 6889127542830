import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ButtonComponent from './ButtonComponent';
import '../styles/CaseCardStyles.scss';
import DocumentModalComponent from './DocumentModalComponent';

const CaseCard = ({ title, img, document, full_content, description, content }) => {
  const [documentModalVisible, setDocumentModalVisible] = useState(false);
  const [documentFileModalVisible, setDocumentFileModalVisible] = useState(false);

  const closeModal = () => {
    setDocumentModalVisible(false);
    setDocumentFileModalVisible(false);
  };

  return (
    <>
      {documentModalVisible && (
        <DocumentModalComponent onClose={closeModal} content={{
          content: full_content,
          type: 'html'
        }} />
      )}
      {documentFileModalVisible && (
        <DocumentModalComponent onClose={closeModal} content={{
          content: document,
          type: 'document'
        }} />
      )}

      <div className="case-card-container">
        <div className="case-card">
          <div className="case-card-header">
            {img && <img className="case-card-logo" src={img} alt="Logo" />}
            <div className="case-card-titles">
              <h3 className="case-card-title">{title}</h3>
              <p className="case-card-subtitle">{description}</p>
            </div>
          </div>
          <div className="case-card-content" dangerouslySetInnerHTML={{ __html: content }} ></div>

          <ButtonComponent
            className="case-card-button button-style-4"
            onClick={() => setDocumentModalVisible(true)}
          >
            <span className='case-card-btn-span'>Подробнее</span>
          </ButtonComponent>

          <div style={{ position: 'absolute', left: '145px', bottom: '0px' }}>
            <ButtonComponent
              className="case-card-button button-style-4"
              onClick={() => setDocumentFileModalVisible(true)}
            >
             <span className='case-card-btn-span'>Документ</span>
            </ButtonComponent>
          </div>
        </div>
      </div>
    </>
  );
};

CaseCard.propTypes = {
  img: PropTypes.string,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  document: PropTypes.string,
  full_content: PropTypes.string,
};

export default CaseCard;

import { useEffect, useState } from "react";
import "../styles/FeedbackModal.scss";
import TextInputRequiredComponent from "./TextInputRequiredComponent";
import PhoneNumberInputComponent from "./PhoneNumberInputComponent";
import ButtonComponent from "./ButtonComponent";

const FeedbackModal = ({ onSuccess, onError }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [formData, setFormData] = useState({ name: "", phone: "" });
  const [error, setError] = useState('');



  const handleOrderConsultation = async () => {
    if (!formData.name || !formData.phone) {
      setError('Необходимо заполнить все поля');
      return;
    }
    try {
      const response = await fetch('https://lims.backend.demowts.ru/api/feedback', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      const responseData = await response.json();
      console.log('Данные, отправляемые на сервер:', formData);

      if (response.ok) {
        console.log('Данные успешно отправлены:', responseData.data.feedback);
        onSuccess(true);
        setIsOpen(false);
      } else {
        console.error('Ошибка при отправке данных на сервер:', responseData.message);
        setError('Произошла ошибка при отправке данных');
        onError(true, 'Произошла ошибка при отправке данных');
      }
    } catch (error) {
      console.error('Ошибка при отправке данных:', error.message);
      setError('Произошла ошибка при отправке данных');
      onError(true, 'Произошла ошибка при отправке данных');
    }
  };



  useEffect(() => {
    const showModal = () => {
      setTimeout(() => {
        setIsOpen(true);
      }, 20000);
    };

    showModal();
  }, []);

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }

    return () => {
      document.body.style.overflow = "";
    };
  }, [isOpen]);
  const handleInputChange = (name, value) => {
    setFormData({ ...formData, [name]: value });
  };

  return (
    <>
      {isOpen && (
        <div className="feedback-modal" onClick={() => setIsOpen(false)}>
          <div className="feedback-modal-container">
            <div className="feedback-modal-content" onClick={(e)=>e.stopPropagation()}>
              <h1 className="feedback-modal-close-header-btn" onClick={() => setIsOpen(false)}>X</h1>
              <h2 className="feedback-modal-title">Свяжитесь с нами:</h2>
              <div className="feedback-modal-form-container">
                <div className="feedback-modal-form-input">
                  <p className="feedback-modal-form-input-label">Как мы можем к вам обращаться?</p>
                  <TextInputRequiredComponent
                    placeholder="Ваше имя"
                    label="Имя"
                    value={formData.name}
                    onChange={(value) => handleInputChange("name", value)}
                  />
                </div>
                <div className="feedback-modal-form-input">
                  <p className="feedback-modal-form-input-label">Как с вами связаться?</p>
                  <PhoneNumberInputComponent
                    placeholder="+7 (---) --- -- --"
                    value={formData.phone}
                    onChange={(value) => handleInputChange("phone", value)}
                  />
                </div>
              </div>
              <ButtonComponent
                className="question-response-button button-style-1"
                type="button"
                onClick={handleOrderConsultation}
              >
                Заказать консультацию
              </ButtonComponent>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default FeedbackModal;

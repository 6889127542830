import React from "react";
import img from "../assets/promotion2.jpg";

const PromotionSection = () => {

  if (new Date() > new Date("Febryary 10, 2025"))
    return (<></>);

  return (
    <img
      src={img}
      alt="promotion"
      style={{ width: "100%", marginTop: "70px", zIndex: 2 }}
    />
  );
};

export default PromotionSection;

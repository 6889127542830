import certificate1 from '../assets/certificate-1.jpg';
import certificate2 from '../assets/certificate-2.jpg';
import certificate3 from '../assets/certificate-3.jpg';
import certificate4 from '../assets/certificate-4.jpg';
import certificate5 from '../assets/certificate-5.jpg';
import certificate6 from '../assets/certificate-6.jpg';
import certificate8 from '../assets/certificate-8.jpg';
import certificate10 from '../assets/certificate-10.jpg';
import certificate11 from '../assets/certificate-11.jpg';  

 export const certificates = [
    certificate1,
    certificate2,
    certificate3,
    certificate4,
    certificate5,
    certificate6,
    certificate8,
    certificate10,
    certificate11,
  ]
import React, { useEffect, useState } from "react";
import "../styles/TextBlockStyles.scss";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { companyLogos } from "../assets/company-logos";

const TextBlockAboutProgram = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [count ,setCount] = useState(5)

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleWindowResize);
   
    if (windowWidth < 450) {
      setCount(2)
    }
    
    if (windowWidth < 1055) {
      setCount(3)
    }
    
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, [windowWidth]);
  const CustomPrevArrow = (props) => (
    <div {...props} style={{top:'30%'}}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="21"
        height="34"
        viewBox="0 0 21 34"
        fill="none"
      >
        <path
          d="M3.33337 3.66666L16.6667 17L3.33337 30.3333"
          stroke="black"
          stroke-width="6"
          stroke-wnecap="round"
        />
      </svg>
    </div>
  );

  const CustomNextArrow = (props) => (
    <div {...props} style={{top:'30%',right:'122px'}}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="21"
        height="34"
        viewBox="0 0 21 34"
        fill="none"
      >
        <path
          d="M17.6667 3.66666L4.33333 17L17.6667 30.3333"
          stroke="black"
          stroke-width="6"
          stroke-winecap="round"
        />
      </svg>
    </div>
  );
  const settings = {
    speed: 1000,
    width:400,
    slidesToShow: count,
    slidesToScroll: 2,
    autoplay: true,
    autoplaySpeed: 3000,
    useCSS:true,
    cssEase: "linear",
    infinite: true,

    
    nextArrow: <CustomPrevArrow />,
    prevArrow: <CustomNextArrow />,

    afterChange: (index) => setCurrentSlide(index),
  };



  return (
    <div className="text-block-program-with-header">
      <h2>О программе:</h2>
      <p className="text-block-program-with-header-text">
        Система LIMS: Совершенствуйте управление качеством и автоматизируйте
        испытания.{" "}
      </p>
      <p className="text-block-program-with-header-text">
        Система LIMS представляет собой интегрированное решение для управления
        качеством и автоматизацией процессов испытаний, используемых на
        производственных предприятиях и в испытательных лабораториях. Хотя роль
        человека в этом процессе остается критически важной, данная система
        значительно упрощает ведение отчетности, снижает нагрузку на сотрудников
        лаборатории и упрощает проверки.
      </p>
      <p className="text-block-program-with-header-text">
        Программа LIMS IT-LAB реализована на базе платформы 1С, оснащена самыми
        передовыми механизмами анализа данных.
      </p>
      <p className="text-block-program-with-header-list-title">
        Система включает в себя следующие модули:
      </p>
      <ul>
        <li>
          LIMS IT-Lab.Базовый - учет оборудования, персонала, документов,
          электронных журналов, планировщик (напоминания и планирование рабочего
          времени), паспорт Лаборатории
        </li>
        <li>LIMS IT-Lab. Протоколы испытаний;</li>
        <li>LIMS IT-Lab. Система менеджмента качества (СМК);</li>
        <li>
          LIMS IT-Lab. Валидация лабораторных контрольных процессов (ВЛК);
        </li>
        <li>LIMS IT-Lab. Акт верификации;</li>
        <li>LIMS IT-Lab. Градуировочные характеристики;</li>
        <li>LIMS IT-Lab. Реактивы;</li>
        <li>LIMS IT-Lab. Пакетная выгрузка во ФГИС ;</li>
        <li>LIMS IT-Lab. Архив;</li>
        <li>LIMS IT-Lab. Неопределенность;</li>
      </ul>
      <p className="text-block-program-with-header-after-list">
        Каждый из этих инструментов успешно прошел проверку временем и был
        освоен сотнями специалистов по всей России. Мы гарантируем стабильную
        работу и надежность наших решений без перебоев и проблем.
      </p>

      <p className="text-block-program-with-header-after-list">Нам доверяют:</p>

      <div className="company-logos-slider">
        <div className="company-logos-slider-container" >
          <Slider {...settings}>
            {companyLogos.map((company, index) => (
              <div key={index} className="company-logos-slider-item" style={{padding:'50px'}}>
                <img src={company.src} alt={company.name} />
                <p>{company.name}</p>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default TextBlockAboutProgram;
